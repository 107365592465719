<template>
  <div class="page">
    <!--<div class="c1"></div>-->
    <div class="c2"></div>
    <!--<div class="c3"></div>-->
    <div class="c4"></div>
    <div class="container flexcenter">
      <router-link :to="{name: 'daoyou-normal'}" class="item left">
        <div class="round flexcenter">
          <img src="~images/mode-normal.png">
        </div>
        <span>普通模式</span>
      </router-link>
      <router-link :to="{name: 'daoyou-family'}" class="item right">
        <div class="round flexcenter">
          <img src="~images/mode-family.png">
        </div>
        <span>家庭模式</span>
      </router-link>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'daoyou',
    data() {
      return {
        searchValue: '',
      }
    },
    methods: {
      doa() {
      },
    }
  };
</script>

<style lang="scss" scoped>
  .page {
    position: relative;
    background-image: linear-gradient(180deg, #FFFDFD 10%, #FFF5DF 100%);
  }

  .c1 {
    position: absolute;
    left: -66px;
    top: 1092px;
    width: 926px;
    height: 315px;
    transform: rotate(11deg);
    border: 1px solid #FACD31;
    border-radius: 460px;
  }
  .c2 {
    position: absolute;
    left: -66px;
    top: 1092px;
    width: 926px;
    height: 315px;
    opacity: 0.07;
    transform: rotate(11deg);
    background-image: linear-gradient(-54deg, #FF9D10 0%, #FCCE09 96%);
  }
  .c3 {
    position: absolute;
    left: 72px;
    top: 938px;
    width: 877px;
    height: 459px;
    transform: rotate(-32deg);
    border: 1px solid #FACD31;
    border-radius: 440px;
  }
  .c4 {
    position: absolute;
    left: 72px;
    top: 938px;
    width: 877px;
    height: 459px;
    opacity: 0.07;
    transform: rotate(-32deg);
    background-image: linear-gradient(-54deg, #FF9D10 0%, #FCCE09 96%);
  }

  .container {
    justify-content: space-between;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    padding: 0 98px;
  }

  .item {
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    color: #777777;
  }

  .round {
    justify-content: center;
    margin-bottom: 30px;
    width: 240px;
    height: 240px;
    background-image: linear-gradient(180deg, #F9D425 0%, #FDAC60 100%);
    box-shadow: 0 4px 20px 0 #FCAD5D;
    border-radius: 120px;
  }
  .round img{
    width: 120px;
    height: 120px;
  }

</style>
